<template>
    <div class="con-wrap">
        <CarrotTitle title="나의 업적관리(KPI/업적리뷰)">
            - PBC/KPI 작성, 제목 클릭 시 작성 페이지로 이동합니다.<br>
            - 제출완료 된 PBC/KPI 수정이 불가합니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div class="dp-table w-100per">

                    <div class="float-left w-50per pr-25">
                        <div class="mb-20">
                            <div class="block-title dp-inblock title-style">PBC</div>
                            <router-link to="PBCAdd" v-if="pbc.hasPBC==false" class="dp-inblock float-right"><button class="btn-default">PBC 작성</button></router-link>
                            <div class="clear"></div>
                        </div>

                        <table class="table-col mt-20">
                            <colgroup>
                                <col width="80">
                                <col width="*">
                                <col width="120">
                                <col width="120">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>PBC</th>
                                    <th>상태</th>
                                    <th>작성일</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in pbc.list" :key="i">
                                    <td>{{ irow.num }}</td>
                                    <td @click="pbc.showDetail(irow)"><span class="btn-view">{{ irow.title }}</span></td>
                                    <td>{{ irow.state_txt }}</td>
                                    <td>{{ irow.wdate }}</td>
                                </tr>
                                <tr v-if="pbc.total==0">
                                    <td colspan="4">PBC 내역이 없습니다.</td>
                                </tr>
                                <!-- <tr>
                                    <td>00</td>
                                    <td><span class="btn-view">2019 PBC</span></td>
                                    <td>작성 중</td>
                                    <td>2016-07-18</td>
                                </tr>
                                <tr>
                                    <td>00</td>
                                    <td><span class="btn-view">2018 PBC</span></td>
                                    <td>제출완료</td>
                                    <td>2016-07-18</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>

                    <div class="float-left w-50per pl-25">
                        <div class="mb-20">
                            <div class="block-title dp-inblock title-style">업적리뷰</div>
                            <router-link to="KPIAdd" v-if="kpi.hasKPI==false" class="dp-inblock float-right"><button class="btn-default">KPI 작성</button></router-link>
                            <div class="clear"></div>
                        </div>

                        <table class="table-col mt-20">
                            <colgroup>
                                <col width="80">
                                <col width="*">
                                <col width="120">
                                <col width="120">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>KPI</th>
                                    <th>상태</th>
                                    <th>작성일</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in kpi.list" :key="i">
                                    <td>{{ irow.num }}</td>
                                    <td @click="kpi.showDetail(irow)"><span class="btn-view">{{ irow.title }}</span></td>
                                    <td>{{ irow.state_txt }}</td>
                                    <td>{{ irow.wdate }}</td>
                                </tr>
                                <tr v-if="kpi.total==0">
                                    <td colspan="4">KPI 내역이 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axios.js'

export default {
    layout:"myPIMS",
    components: {
    },
    setup() {
        const router = new useRouter();

        const pbc = reactive({
            page   : 1,
            rows   : 20,

            hasPBC : false,
            list : [], total : 0,

            showDetail: (i) => {
                if( i.state == "T" ){
                    router.push({
                        name   : 'myPIMS-PBCAdd',
                        params : { page:pbc.page, rows:pbc.rows }
                    });
                } else {
                    router.push({
                        name   : 'myPIMS-PBCView-idx',
                        params : { idx:i.idx, page:pbc.page, rows:pbc.rows }
                    });
                }
            },

            doSearch: () => {
                let params = {
                    page   : pbc.page,
                    rows   : pbc.rows,
                };

                axios.get('/rest/mypims/myPBCList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pbc.list   = res.data.list;
                        pbc.total  = res.data.total;
                        pbc.hasPBC = res.data.has_pbc;
                    } else {
                        console.info(res.data.err_msg);
                    }
                })
            },
        });

        const kpi = reactive({
            page   : 1,
            rows   : 20,

            hasKPI : false,
            list : [], total : 0,

            showDetail: (i) => {
                if( i.state == "T" ){
                    router.push({
                        name   : 'myPIMS-KPIAdd',
                        params : { page:pbc.page, rows:pbc.rows }
                    });
                } else {
                    router.push({
                        name   : 'myPIMS-KPIView-idx',
                        params : { idx:i.idx, page:pbc.page, rows:pbc.rows }
                    });
                }
            },

            doSearch: () => {
                let params = {
                    page   : kpi.page,
                    rows   : kpi.rows,
                };

                axios.get('/rest/mypims/myKPIList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        kpi.list   = res.data.list;
                        kpi.total  = res.data.total;
                        kpi.hasKPI = res.data.has_kpi;
                    } else {
                        console.info(res.data.err_msg);
                    }
                })
            },
        });

        onMounted(() => {
            // Mounted

            pbc.doSearch();
            kpi.doSearch();
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {pbc, kpi};
    }
}
</script>

<style lang="scss" scoped>
</style>